export type Nullable<T> = T extends null | undefined ? never : T | null;

type DeepNullableArray<T extends unknown[]> = (T | null)[];

type DeepNullableObject<T extends object> = {
  [k in keyof T]: T[keyof T] | null;
};

/**
 * Make all proverties of a definition not nullable
 */
export type DeepNullable<T> = T extends (...args: unknown[]) => unknown
  ? T
  : T extends unknown[]
  ? DeepNullableArray<T>
  : T extends object
  ? DeepNullableObject<T>
  : T;

/**
 * Remove readonly modifier
 */
export type Mutable<T> = { -readonly [P in keyof T]: T[P] };
export type Writable<T> = Mutable<T>;

export const nameOf = <T>(name: keyof T): string => String(name);

/**
 * @see https://fettblog.eu/typescript-hasownproperty/
 */
export function objectHasOwnProperty<O extends object, P extends PropertyKey>(
  obj: O,
  prop: P,
): obj is O & Record<P, unknown> {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}
