import {
  ExtraErrorData as ExtraErrorDataIntegration,
  HttpClient as HttpClientIntegration,
} from '@sentry/integrations';
import {
  captureException,
  captureMessage,
  configureScope,
  init,
  makeBrowserOfflineTransport,
  makeFetchTransport,
  SeverityLevel,
  withScope,
} from '@sentry/react';
import { Context } from '@sentry/types';

import { getEnvVar } from '@sonar/types/env-vars/env-vars';

import { FunctionPropertyNames, Tags } from './types';

const severityLogMap: Record<SeverityLevel, FunctionPropertyNames<Console>> = {
  fatal: 'error',
  error: 'error',
  warning: 'warn',
  log: 'log',
  info: 'info',
  debug: 'debug',
};

const shouldReportToConsole = () => {
  return getEnvVar('NODE_ENV') !== 'production';
};

const shouldReportToSentry = () => {
  return getEnvVar('NEXT_PUBLIC_SENTRY_ENABLED') === 'true';
};

function report<O extends object>(
  severity: SeverityLevel,
  messageOrError: string | Error,
  context: string,
  data?: Tags<O>,
  extra?: Context,
) {
  if (shouldReportToConsole()) {
    const args = [context, messageOrError, data, extra].filter(Boolean);
    if (severity === 'fatal') {
      args.unshift('FATAL');
    }
    global.console[severityLogMap[severity]](...args);
  }

  if (shouldReportToSentry()) {
    withScope(scope => {
      scope.setLevel(severity);
      scope.setTag('context', context);
      if (data) {
        scope.setTags(data);
      }
      if (extra) {
        scope.setContext('extra', extra);
      }
      if (messageOrError instanceof Error) {
        captureException(messageOrError);
      } else {
        captureMessage(messageOrError);
      }
    });
  }
}

const logger = {
  init(version: string) {
    if (shouldReportToSentry()) {
      const normalizeDepth = 6;
      // Init Sentry SDK, https://docs.sentry.io/platforms/javascript/react/
      init({
        dsn: getEnvVar('NEXT_PUBLIC_SENTRY_DNS'),
        environment: getEnvVar('NODE_ENV'),
        release: `sonar@v${version}`,
        normalizeDepth,
        sampleRate: 0.1,
        integrations: [
          // Check https://docs.sentry.io/platforms/javascript/configuration/integrations/plugin/ for integration options
          new ExtraErrorDataIntegration({ depth: normalizeDepth - 1 }),
          new HttpClientIntegration(),
        ],
        transport: makeBrowserOfflineTransport(makeFetchTransport),
      });
    }
  },

  configure(userId: string, username: string, email: string, userLanguage: string) {
    if (shouldReportToSentry()) {
      // Sending curent users to Sentry
      configureScope(scope => {
        scope.setUser({ id: userId, username, email });
        scope.setTag('app_locale', userLanguage);
      });
    }
  },

  fatal<O extends object>(error: Error, context: string, data?: Tags<O>, extra?: Context) {
    report('fatal', error, context, data, extra);
  },

  error<O extends object>(error: Error, context: string, data?: Tags<O>, extra?: Context) {
    report('error', error, context, data, extra);
  },

  warning<O extends object>(
    message: Error | string,
    context: string,
    data?: Tags<O>,
    extra?: Context,
  ) {
    report('warning', message, context, data, extra);
  },

  log<O extends object>(message: string, context: string, data?: Tags<O>, extra?: Context) {
    report('log', message, context, data, extra);
  },

  info<O extends object>(message: string, context: string, data?: Tags<O>, extra?: Context) {
    report('info', message, context, data, extra);
  },

  debug<O extends object>(message: string, context: string, data?: Tags<O>, extra?: Context) {
    report('debug', message, context, data, extra);
  },
};

export default logger;
