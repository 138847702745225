import { z } from 'zod';

export const envSchema = z.object({
  NEXT_PUBLIC_APP_ENV: z.string(),
  NEXT_PUBLIC_AWS_API_GATEWAY_TILLS: z.string(),
  NEXT_PUBLIC_AWS_API_GATEWAY: z.string(),
  NEXT_PUBLIC_AWS_COGNITO_USER_POOL_ID: z.string(),
  NEXT_PUBLIC_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID: z.string(),
  NEXT_PUBLIC_AWS_REGION: z.string(),
  NEXT_PUBLIC_AWS_WEBSOCKET_URL: z.string(),
  NEXT_PUBLIC_CLIEND_ID: z.string(),
  NEXT_PUBLIC_CLIEND_SECRET: z.string(),
  NEXT_PUBLIC_DIGITAL_MENU_URL: z.string(),
  NEXT_PUBLIC_DISABLE_WALKER_TRACKING: z.enum(['true', 'false']).optional(),
  NEXT_PUBLIC_GA_IDENTIFIER: z.string(),
  NEXT_PUBLIC_GTM_ID: z.string(),
  NEXT_PUBLIC_HOT_KPI_WEBSOCKET_URL: z.string(),
  NEXT_PUBLIC_IGNORE_LEGACY_ERRORS: z.enum(['true', 'false']).optional(),
  NEXT_PUBLIC_INSTANA_KEY: z.string(),
  NEXT_PUBLIC_INSTANA_REPORT_URL: z.string(),
  NEXT_PUBLIC_INTEGRATION_REQUEST_FORM_URL: z.string(),
  NEXT_PUBLIC_LEGACY_BO_URL: z.string(),
  NEXT_PUBLIC_OBYPAY_BO_URL: z.string(),
  NEXT_PUBLIC_ONBOARDING_ADDRESS_TYPEFORM_ID: z.string(),
  NEXT_PUBLIC_ONBOARDING_WEBSOCKET_URL: z.string(),
  NEXT_PUBLIC_ONETRUST_DOMAIN_ID: z.string(),
  NEXT_PUBLIC_ONETRUST_ENABLED: z.enum(['true', 'false']),
  NEXT_PUBLIC_SALESFORCE_CHAT_ENDPOINT: z.string(),
  NEXT_PUBLIC_SALESFORCE_CHAT_ID: z.string(),
  NEXT_PUBLIC_SALESFORCE_CHAT_NAME: z.string(),
  NEXT_PUBLIC_SALESFORCE_CHAT_SECRET_URL: z.string(),
  NEXT_PUBLIC_SALESFORCE_CHAT_WITH_USER_VERIF_ENDPOINT: z.string(),
  NEXT_PUBLIC_SALESFORCE_CHAT_WITH_USER_VERIF_ID: z.string(),
  NEXT_PUBLIC_SALESFORCE_CHAT_WITH_USER_VERIF_NAME: z.string(),
  NEXT_PUBLIC_SALESFORCE_CHAT_WITH_USER_VERIF_SECRET_URL: z.string(),
  NEXT_PUBLIC_SEGMENT_KEY: z.string(),
  NEXT_PUBLIC_SENTRY_DNS: z.string(),
  NEXT_PUBLIC_SENTRY_ENABLED: z.enum(['true', 'false']).optional(),
  NEXT_PUBLIC_WEBSOCKET_URL: z.string(),
  NEXT_PUBLIC_WOOTRIC_ACCOUNT_TOKEN: z.string(),
  NEXT_PUBLIC_WOOTRIC_CES_ACCOUNT_TOKEN: z.string(),
  NEXT_PUBLIC_WOOTRIC_DEBUG: z.enum(['true', 'false']).optional(),
  NEXT_PUBLIC_WOOTRIC_ENABLED: z.enum(['true', 'false']).optional(),
  NEXT_PUBLIC_WOOTRIC_PRODUCT_NAME: z.string(),
  NEXT_PUBLIC_WOOTRIC_SCRIPT_URL: z.string(),
  NEXT_PUBLIC_XERO_APP_ID: z.string(),
  NODE_ENV: z.enum(['development', 'production', 'test']).optional(),
});

// This is necessary because at runtime process.env is not defined, only at build time
export const envVars = {
  NEXT_PUBLIC_APP_ENV: process.env.NEXT_PUBLIC_APP_ENV,
  NEXT_PUBLIC_AWS_API_GATEWAY_TILLS: process.env.NEXT_PUBLIC_AWS_API_GATEWAY_TILLS,
  NEXT_PUBLIC_AWS_API_GATEWAY: process.env.NEXT_PUBLIC_AWS_API_GATEWAY,
  NEXT_PUBLIC_AWS_COGNITO_USER_POOL_ID: process.env.NEXT_PUBLIC_AWS_COGNITO_USER_POOL_ID,
  NEXT_PUBLIC_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID:
    process.env.NEXT_PUBLIC_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
  NEXT_PUBLIC_AWS_REGION: process.env.NEXT_PUBLIC_AWS_REGION,
  NEXT_PUBLIC_AWS_WEBSOCKET_URL: process.env.NEXT_PUBLIC_AWS_WEBSOCKET_URL,
  NEXT_PUBLIC_CLIEND_ID: process.env.NEXT_PUBLIC_CLIEND_ID,
  NEXT_PUBLIC_CLIEND_SECRET: process.env.NEXT_PUBLIC_CLIEND_SECRET,
  NEXT_PUBLIC_DIGITAL_MENU_URL: process.env.NEXT_PUBLIC_DIGITAL_MENU_URL,
  NEXT_PUBLIC_DISABLE_WALKER_TRACKING: process.env.NEXT_PUBLIC_DISABLE_WALKER_TRACKING ?? true,
  NEXT_PUBLIC_GA_IDENTIFIER: process.env.NEXT_PUBLIC_GA_IDENTIFIER,
  NEXT_PUBLIC_GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
  NEXT_PUBLIC_HOT_KPI_WEBSOCKET_URL: process.env.NEXT_PUBLIC_HOT_KPI_WEBSOCKET_URL,
  NEXT_PUBLIC_IGNORE_LEGACY_ERRORS: process.env.NEXT_PUBLIC_IGNORE_LEGACY_ERRORS ?? false,
  NEXT_PUBLIC_INSTANA_KEY: process.env.NEXT_PUBLIC_INSTANA_KEY,
  NEXT_PUBLIC_INSTANA_REPORT_URL: process.env.NEXT_PUBLIC_INSTANA_REPORT_URL,
  NEXT_PUBLIC_INTEGRATION_REQUEST_FORM_URL: process.env.NEXT_PUBLIC_INTEGRATION_REQUEST_FORM_URL,
  NEXT_PUBLIC_LEGACY_BO_URL: process.env.NEXT_PUBLIC_LEGACY_BO_URL,
  NEXT_PUBLIC_OBYPAY_BO_URL: process.env.NEXT_PUBLIC_OBYPAY_BO_URL,
  NEXT_PUBLIC_ONBOARDING_ADDRESS_TYPEFORM_ID:
    process.env.NEXT_PUBLIC_ONBOARDING_ADDRESS_TYPEFORM_ID,
  NEXT_PUBLIC_ONBOARDING_WEBSOCKET_URL: process.env.NEXT_PUBLIC_ONBOARDING_WEBSOCKET_URL,
  NEXT_PUBLIC_ONETRUST_DOMAIN_ID: process.env.NEXT_PUBLIC_ONETRUST_DOMAIN_ID,
  NEXT_PUBLIC_ONETRUST_ENABLED: process.env.NEXT_PUBLIC_ONETRUST_ENABLED,
  NEXT_PUBLIC_SALESFORCE_CHAT_ENDPOINT: process.env.NEXT_PUBLIC_SALESFORCE_CHAT_ENDPOINT,
  NEXT_PUBLIC_SALESFORCE_CHAT_ID: process.env.NEXT_PUBLIC_SALESFORCE_CHAT_ID,
  NEXT_PUBLIC_SALESFORCE_CHAT_NAME: process.env.NEXT_PUBLIC_SALESFORCE_CHAT_NAME,
  NEXT_PUBLIC_SALESFORCE_CHAT_SECRET_URL: process.env.NEXT_PUBLIC_SALESFORCE_CHAT_SECRET_URL,
  NEXT_PUBLIC_SALESFORCE_CHAT_WITH_USER_VERIF_ENDPOINT:
    process.env.NEXT_PUBLIC_SALESFORCE_CHAT_WITH_USER_VERIF_ENDPOINT,
  NEXT_PUBLIC_SALESFORCE_CHAT_WITH_USER_VERIF_ID:
    process.env.NEXT_PUBLIC_SALESFORCE_CHAT_WITH_USER_VERIF_ID,
  NEXT_PUBLIC_SALESFORCE_CHAT_WITH_USER_VERIF_NAME:
    process.env.NEXT_PUBLIC_SALESFORCE_CHAT_WITH_USER_VERIF_NAME,
  NEXT_PUBLIC_SALESFORCE_CHAT_WITH_USER_VERIF_SECRET_URL:
    process.env.NEXT_PUBLIC_SALESFORCE_CHAT_WITH_USER_VERIF_SECRET_URL,
  NEXT_PUBLIC_SEGMENT_KEY: process.env.NEXT_PUBLIC_SEGMENT_KEY,
  NEXT_PUBLIC_SENTRY_DNS: process.env.NEXT_PUBLIC_SENTRY_DNS,
  NEXT_PUBLIC_SENTRY_ENABLED: process.env.NEXT_PUBLIC_SENTRY_ENABLED,
  NEXT_PUBLIC_WEBSOCKET_URL: process.env.NEXT_PUBLIC_WEBSOCKET_URL,
  NEXT_PUBLIC_WOOTRIC_ACCOUNT_TOKEN: process.env.NEXT_PUBLIC_WOOTRIC_ACCOUNT_TOKEN,
  NEXT_PUBLIC_WOOTRIC_CES_ACCOUNT_TOKEN: process.env.NEXT_PUBLIC_WOOTRIC_CES_ACCOUNT_TOKEN,
  NEXT_PUBLIC_WOOTRIC_DEBUG: process.env.NEXT_PUBLIC_WOOTRIC_DEBUG,
  NEXT_PUBLIC_WOOTRIC_ENABLED: process.env.NEXT_PUBLIC_WOOTRIC_ENABLED,
  NEXT_PUBLIC_WOOTRIC_PRODUCT_NAME: process.env.NEXT_PUBLIC_WOOTRIC_PRODUCT_NAME,
  NEXT_PUBLIC_WOOTRIC_SCRIPT_URL: process.env.NEXT_PUBLIC_WOOTRIC_SCRIPT_URL,
  NEXT_PUBLIC_XERO_APP_ID: process.env.NEXT_PUBLIC_XERO_APP_ID,
  NODE_ENV: process.env.NODE_ENV,
};
