class EventError extends Error {
  readonly event: Event;

  constructor(message: string, event: Event) {
    super(message);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, EventError);
    }

    this.name = 'EventError';
    this.event = event;
  }
}

export default EventError;
