class GenericError extends Error {
  readonly original: unknown;

  constructor(message: string, original: unknown) {
    super(message);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this);
    }

    this.name = 'GenericError';
    this.original = original;
  }
}

export default GenericError;
