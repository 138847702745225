import { AxiosError } from 'axios';
import { isObject } from 'lodash';

import { objectHasOwnProperty } from '@sonar/helpers/types';

import ApiError from './ApiError';

function isAxiosError(error: unknown): error is AxiosError {
  return (
    isObject(error) &&
    objectHasOwnProperty(error, 'isAxiosError') &&
    typeof error.isAxiosError === 'boolean' &&
    error.isAxiosError
  );
}

class AwsApiError extends ApiError {
  constructor(error: unknown) {
    if (isAxiosError(error)) {
      super(error.message, error.response?.status, error.toJSON());
    } else {
      super(error);
    }
    this.name = 'AwsApiError';
  }
}

export default AwsApiError;
