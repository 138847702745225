import { initReactI18next } from 'react-i18next';
// eslint-disable-next-line import/no-named-as-default
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';

import { getFallbackLanguagesCodes } from '@tillersystems/translations';

import { logger } from '@sonar/errors';
import translate_ca from '@sonar/locales/ca/translations.json';
import translate_de from '@sonar/locales/de/translations.json';
import translate_en from '@sonar/locales/en/translations.json';
import translate_en_GB from '@sonar/locales/en-GB/translations.json';
import translate_es from '@sonar/locales/es/translations.json';
import translate_fr from '@sonar/locales/fr/translations.json';
import translate_it from '@sonar/locales/it/translations.json';
import translate_pt from '@sonar/locales/pt/translations.json';

export const languages = [
  { code: 'ca', name: 'Catalan' },
  { code: 'de', name: 'German' },
  { code: 'en', name: 'English' },
  { code: 'en-GB', name: 'English(UK)' },
  { code: 'es', name: 'Spanish' },
  { code: 'fr', name: 'French' },
  { code: 'it', name: 'Italian' },
  { code: 'pt', name: 'Portugese' },
];

const availableLanguages = languages.map(lng => lng.code);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // use i18next JSON v3 to keep compatibility with plural suffixes of translations
    // https://www.i18next.com/misc/json-format#i18next-json-v3
    compatibilityJSON: 'v3',
    fallbackLng: code => getFallbackLanguagesCodes({ languageCode: code, availableLanguages }),
    load: 'currentOnly', // to handle en-GB : https://github.com/i18next/i18next/issues/1506#issuecomment-694683792
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
    resources: {
      ca: { translations: translate_ca },
      de: { translations: translate_de },
      en: { translations: translate_en },
      'en-GB': { translations: translate_en_GB },
      es: { translations: translate_es },
      fr: { translations: translate_fr },
      it: { translations: translate_it },
      pt: { translations: translate_pt },
    },

    debug: process.env.NODE_ENV === 'development',

    keySeparator: '.',

    interpolation: {
      escapeValue: false, // not needed for react!!
    },
  })
  .then(() => {
    const language = availableLanguages.includes(i18n.language)
      ? i18n.language
      : i18n.language?.split('-')[0];
    if (language) {
      moment.locale(language);
      import(`moment/locale/${language}.js`).catch(err =>
        logger.error(err, `i18n: unable to load moment locale ${language}`),
      );
    }
  });

const getSuffix = n => Number(n > 1 ? 2 : n);

languages.forEach(({ code, name }) => {
  i18n.services.pluralResolver.addRule(code, {
    name,
    numbers: [0, 1, 2],
    plurals: getSuffix,
  });
});

export default i18n;
