import { z } from 'zod';

import { envSchema, envVars } from './schema.mjs';

export type Env = z.infer<typeof envSchema>;

export type EnvVar = keyof Env;

export function getEnvVar(envVar: EnvVar): string {
  const envValue = envVars[envVar];
  if (envValue === undefined) {
    throw new Error(`Missing environment variable: ${envVar}`);
  }

  return envValue.toString();
}
