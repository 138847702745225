import _, { Dictionary } from 'lodash';

import { objectHasOwnProperty } from '@sonar/helpers/types';

import AuthenticationError from './AuthenticationError';
import { ErrorCode } from './types';

const AwsErrorCodes: Dictionary<number> = {
  InvalidParameterException: ErrorCode.BadRequest,
  NotAuthorizedException: ErrorCode.Forbidden,
  ServerError: ErrorCode.ServerError,
  UserNotFoundException: ErrorCode.NotFound,
} as const;

class AwsAuthenticationError extends AuthenticationError {
  /**
   * Create a new instance of AwsAuthenticationError.
   *
   * @param original - the original error
   */
  constructor(original?: unknown) {
    if (
      typeof original === 'object' &&
      original !== null &&
      objectHasOwnProperty(original, 'code') &&
      typeof original.code === 'string' &&
      objectHasOwnProperty(original, 'message') &&
      typeof original.message === 'string' &&
      _.has(AwsErrorCodes, original.code)
    ) {
      super(original.message, AwsErrorCodes[original.code], original);
    } else {
      super('Unknown AwsAuthenticationError', ErrorCode.ServerError, original);
    }
  }
}

export default AwsAuthenticationError;
