import AwsApiError from './AwsApiError';

class ReportAwsApiError extends AwsApiError {
  constructor(error: unknown) {
    super(error);
    this.name = 'ReportAwsApiError';
  }
}

export default ReportAwsApiError;
