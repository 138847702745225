import dynamic from 'next/dynamic';
import Head from 'next/head';

import { getEnvVar } from '@sonar/types/env-vars/env-vars';

import '@sonar/i18n';

const App = dynamic(() => import('@sonar/app'), { ssr: false });

export default function AppRoute() {
  return (
    <>
      <Head>
        <title>SumUp POS Pro</title>
        <link rel="icon" href={`/favicon.${getEnvVar('NEXT_PUBLIC_APP_ENV')}.ico`} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <App />
    </>
  );
}
