import { Primitive } from '@sentry/types';

export enum ErrorCode {
  BadRequest = 400,
  Forbidden = 403,
  NotFound = 404,
  ServerError = 500,
}

export type Tags<O extends object> = {
  [P in keyof O]: O[P] extends Primitive ? O[P] : never;
};

export type FunctionPropertyNames<T> = {
  [K in keyof T]: T[K] extends (...args: unknown[]) => unknown ? K : never;
}[keyof T];
