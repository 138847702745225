import { objectHasOwnProperty } from '@sonar/helpers/types';

import GenericError from './GenericError';

/**
 * ApiError.
 * Error thrown when a validation fail.
 */
class ApiError extends GenericError {
  static defaultMessage: string;

  readonly code?: number;

  constructor(original: unknown);
  constructor(message: string, code?: number, original?: unknown);
  constructor(originalOrMessage: unknown, code?: number, original?: unknown) {
    if (typeof originalOrMessage === 'object' && originalOrMessage !== null) {
      code =
        objectHasOwnProperty(originalOrMessage, 'code') &&
        typeof originalOrMessage.code === 'number'
          ? originalOrMessage.code
          : undefined;
      const message =
        (objectHasOwnProperty(originalOrMessage, 'message') &&
          typeof originalOrMessage.message === 'string' &&
          originalOrMessage.message) ||
        ApiError.defaultMessage;
      super(message, originalOrMessage);
    } else {
      const codeMessage = code ? `(code ${code}) ` : '';
      const errorMessage =
        (typeof originalOrMessage === 'string' && originalOrMessage) || ApiError.defaultMessage;
      super(`${codeMessage}${errorMessage}`, original);
    }

    this.code = code;
    this.name = 'ApiError';
  }
}

ApiError.defaultMessage = 'An unknown error occurred';

export default ApiError;
