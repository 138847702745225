class AuthenticationError extends Error {
  private readonly errorMessage: string;

  private readonly errorCode: number;

  readonly original: unknown;

  /**
   * Create a new instance of AuthenticationError.
   *
   * @param message - the message received as error
   * @param code - the code of the error
   * @param original - the original error
   */
  constructor(message: string, code: number, original?: unknown) {
    const errorMessage = message || 'An unknown error occurred';
    const errorCode = code ? ` (code ${code})` : '';

    super(`AuthenticationError${errorCode}: ${errorMessage}`);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AuthenticationError);
    }

    this.name = 'AuthenticationError';
    this.errorMessage = errorMessage;
    this.errorCode = code;
    this.original = original;
  }

  get message() {
    return this.errorMessage;
  }

  get code() {
    return this.errorCode;
  }
}

export default AuthenticationError;
